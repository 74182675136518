.Tabs {
    font-weight: var(--medium-bold);
    display: flex;
    overflow-x: auto;
}

.tab {
    margin-right: 24px;
    color: var(--black50) !important;
    min-width: fit-content !important;
    font-size: var(--d-font-size) !important;
    padding-bottom: 8px !important;
    border-bottom: 2px solid transparent;
}

.active {
    color: var(--primary) !important;
    border-bottom: 2px solid var(--primary)  !important;
}
