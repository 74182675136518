.newIcon {
    cursor: pointer !important;
    margin-left: 12px;
    font-size: 16px;
    @media (--desktop) {
        margin-left: var(--xxl-spacing) !important;
    }
}

.showSearch {
    width: 45px;
    height: 45px;
    right: 0;
    border-left: 0;
    z-index: 1001;
}

.header {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0;
    background: var(--primary);
}

.right {
    font-size: var(--h5-font-size);
    padding-left: var(--m-spacing);
    font-weight: var(--semi-bold);
    flex: 1;
    color: var(--secondary);

    @media (--phone) {
        font-size: var(--h4-font-size);
        width: 60%;
        padding-left: 8px;
    }
}

.shop {
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    /* flex: 1; */
}

.flex {
    display: flex;
    align-items: center;
    margin-right: 124px;

    @media (--phone) {
        display: flex;
        align-items: center;
        margin-right: var(--m-spacing);        
    }
}

.bigName {
}

.count {
    font-size: var(--xxs-font-size);
    font-weight: var(--semi-bold);
    height: 10px;
    width: 10px;
    color: var(--secondary);
    border: 2px solid var(--secondary);
    background: var(--primary);
    border-radius: 50%;
    position: absolute;
    top: -6px;
    right: -6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.relative {
    position: relative;
}

.emptyIcon {
    cursor: pointer;
    height: auto !important;
    width: auto !important;
    border-radius: 0 !important;
    padding: var(--s-spacing);
}

.shopIcon {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    margin-left: 70px;

    @media (--phone) {
        width: 27px;
        height: 27px;
        margin-left: var(--m-spacing);
    }
}

.default {
    background: var(--secondary-grey);
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.landingPageLogo{
    width: 106px;
    height: 33px;

    @media (--desktop) {
        width: 162px;
        height: 50px;
    }
}

.headerLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--l-spacing) 0;
}

.headerText {
    margin-left: var(--xxxxxxl-spacing) !important;
    font-size: var(--h5-font-size);
    color: var(--secondary);
    cursor: pointer;
}

