.topBar {
    background: var(--secondary);
    position: fixed;
    z-index: 999;
    width: 100%;
}

.tabClass {
    padding: 0 16px !important;
    margin-right: 0 !important;
    color: var(--black50);
    font-size: var(--d-font-size) !important;
    padding-bottom: 8px !important;
    border-bottom: 2px solid var(--black110);
}

.tabs {
    padding-top: var(--m-spacing);
}

.overflow {
    position: relative;
    padding-top: 93px;
}

.cartHeaderStyles {
    padding: 8px 16px;
    width: calc(100% - 32px);
    position: relative;
    height: 51px;
    background: var(--secondary);
    z-index: 9;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    @media (--desktop) {
        position: relative;
        box-shadow: none;
        border-bottom: 1px solid var(--black110);
    }
}


.topBarWrapper{
    
}