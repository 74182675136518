.newIcon {
    cursor: pointer !important;
    margin-left: 12px;
    font-size: 16px;
    @media (--desktop) {
        margin-left: var(--xxl-spacing) !important;
        
    }
 }
 
 
 .addPost {
    width: 45px;
    height: 45px;
    right: 0;
    border-left: 0;
    z-index: 1001;
    top: 0;
 }
 
 
 .showSearch {
    width: 45px;
    height: 45px;
    right: 0;
    border-left: 0;
    z-index: 1001;
    color: var(--primary);
 }
 
 
 .header {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0;
    background: var(--primary);
 }
 
 
 .menu {
    position: sticky;
    width: 50% !important;
    margin-left: auto;
    margin-right: 0;
    right: 0 !important;
    top: 32px !important;
    background: transparent !important;
    box-shadow: none !important;
 }
 
 
 .left10 {
    margin-left: 8px !important;
    height: 30px;
 }
 
 
 .right212 {
   color: var(--primary);
   font-weight: var(--semi-bold);
   text-transform: uppercase;
   padding-left: 8px;
   font-size: 12px !important ;
}
 
 
 .shop {
    padding: 0;
    display: flex;
    align-items: center;
    height: 56px;
    cursor: pointer;
    @media (--desktop) {
      border-right: 1px solid var(--primary);
      width: 30%;
    }

    @media(--phone) {
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    }
}

 
 .flex {
    display: flex;
    align-items: center;
    margin-right: 70px;
    
    @media (--phone) {
      margin-right: 10px;
    }

 }
 
 
 .count {
    font-size: var(--xs-font-size);
    font-weight: var(--semi-bold);
    height: 12px;
    width: 12px;
    color: var(--secondary);
    border: 2px solid var(--secondary);
    background: var(--primary);
    border-radius: 50%;
    position: absolute;
    top: -6px;
    right: -2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 
 .relative {
    position: relative;
 }
 
 
 .emptyIcon {
    cursor: pointer;
    height: auto !important;
    width: auto !important;
    border-radius: 0 !important;
    padding: var(--s-spacing);
 }
 
 
 .shopIcon {
    cursor: pointer;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    
    @media (--desktop) {
        cursor: pointer;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-left: 70px;

    }

 }
 
 .btn{
   cursor: pointer;
    border: 1px solid var(--primary);
    padding: 6px 24px;
    border-radius: 20px;
    background-color: white;
    margin-left: var(--l-spacing);
    color: var(--primary);
    font-size: 14px;
 }
.aboutUs{
   cursor: pointer;
   border: 1px solid var(--primary);
   padding: 6px 24px;
   border-radius: 20px;
   background-color: white;
   margin-left: var(--l-spacing);
   color: var(--primary);
   font-size: 14px;
}
 
 .default {
    background: var(--secondary-grey);
    height: 32px;
    width: 32px;
    border-radius: 50%;
 }
 
 
 .landingPageLogo{
    width: 106px;
    height: 33px;
 
 
    @media (--desktop) {
        width: 162px;
        height: 50px;
    }
 }
 
 
 .headerLinks {
    display: flex;
    align-items: center;
    padding-left: var(--xxl-spacing) ;
    padding-right: 70px;
    border-right: 1px solid var(--primary);
    width: 30%;
    margin-right: var(--xxl-spacing);
    height: 56px;
 }
 
 