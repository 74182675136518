.headerStyles {
    padding: 8px 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}

.anchor {
    font-size: var(--m-font-size);
    color: var(--primary);
    text-decoration: none;
}

.rte {
    margin: 8px 0;
    color: var(--black60);
}

.rte p {
    margin: 0 !important;
}
