:global(.mobile) {

    .catalogs {
        margin-bottom: var(--m-spacing);
        display: flex;
        height: 100%;
        width: 100%;
        flex-wrap: wrap;
    }

    .catalog {
        display: flex;
        flex-direction: column;
        /* align-items: flexStart; */
        width: 100%;
        padding: var(--m-spacing);

        .catalogImg {
            height: 180px;
            width: 100%;
            border-radius: 0px;
            color: var(--black);
            font-size: var(--h5-font-size);
            font-weight: var(--semi-bold);
            object-fit: contain;
        }

        .noCatalogImg {
            height: 180px;
            width: 100%;
        }

        .catalogLabel {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--m-font-size);
            color: var(--primary);
            display: flex;
            text-transform: uppercase;
        }

        .viewAll {
            display: flex;
            align-items: center;
            padding-left: var(--xs-spacing);
        }

    }
    .heading {
        font-size: var(--h2-font-size);
        color: var(--primary);
        display: flex;
        justify-content: center;
    }

    .viewAllbtn {
        border-radius: 0;
        border: 1px solid var(--primary);
        padding: 10px 32px;
        background-color: var(--primary);
        margin-top: var(--xxxxxxl-spacing);
        display: flex;
        justify-content: center;



        span {
            font-size: var(--d-font-size);
            color: var(--primary5);
        }
    }

    .viewButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

}

:global(.desktop) {

    .catalogs {
        margin-bottom: var(--m-spacing);
        display: flex;
        height: 100%;
        width: 1038px;
        margin: 0 auto;
        flex-wrap: wrap;
    }

    .catalog {
        display: flex;
        flex-direction: column;
        align-items: flexStart;
        /* position: relative; */
        width: 50%;
        padding: var(--m-spacing);

        .catalogImg {
            width: 495px;
            height: 326px;
            border-radius: 0px;
            color: var(--black);
            font-size: var(--h4-font-size);
            font-weight: var(--semi-bold);
            object-fit: cover;

        }

        .noCatalogImg {
            width: 495px;
            height: 326px;
        }

        .catalogLabel {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--h5-font-size);
            color: var(--primary);
            text-transform: uppercase;
        }

    }

    .viewAllbtn {
        border-radius: 0;
        border: 1px solid var(--primary);
        padding: 11px 28px;
        background-color: var(--primary);
        margin-top: var(--xxxxxxl-spacing);
        display: flex;
        justify-content: center;



        span {
            font-size: var(--h5-font-size);
            color: var(--primary5);
        }
    }

    .viewButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .heading {
        font-size: 34px;
        font-weight: var(--light-bold);
        color: var(--primary);
        display: flex;
        justify-content: center;
        margin-top: 88px;
        margin-bottom: 34px;
    }
}


.templates {
    position: relative;
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (--phone) {
        /* display: flex; */
        position: relative;
        justify-content: center;
        flex-direction: row;
    }

    .slideButton {
        /* position: relative; */
        z-index: 10;
        position: absolute;

        &:last-child {
            top: 373px;
            left: calc(100% - 110px);
        }

        &:first-child {
            top: 373px;
            left: 70px;
        }
    }

    @media (--phone) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.template {
    display: flex;
    justify-content: center;
    width: 100%;

    .templateImage {
        width: 100%;
        height: 746px;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        align-items: center;
        background-size: 100% 100%;
        @media (--phone) {
            display: flex;
            background-repeat: no-repeat;
            width: 100%;
            height: 192px;
            flex-direction: column;
            justify-content: center;
            background-size: 100% 100%;
            padding: 0 var(--xl-spacing);
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }

    .tplTitle {
        font-weight: var(--semi-bold);
        font-size: var(--h4-font-size);
        color: var(--secondary);
        padding-bottom: var(--s-spacing);
        text-align: center;
    }

    .tplDesc {
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--secondary);
        text-align: center;
    }
}

.headerStyles {
    padding: 8px 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    background-color: var(--primary);
}
