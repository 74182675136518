.topBar {
    background: var(--secondary);
    position: fixed;
    z-index: 999;
    width: 100%;
    @media (--phone) {
      width: 100%;
      position: fixed;
    }

 }
 
 
  .overflow {
    position: relative;
    padding: 0%;
 } 
 
 
 .cartHeaderStyles {
    padding: 8px 16px;
    width: calc(100% - 32px);
    position: fixed;
    height: 51px;
    background: var(--secondary);
    z-index: 9;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    @media (--desktop) {
        position: relative;
        box-shadow: none;
        border: 2px solid var(--primary);
    }
 }
 
/*  
  .topBarContainer {
    width: 100%;

 }  */
 