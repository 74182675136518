.banner {
    padding-left: var(--m-spacing) !important;
    z-index: 1001;
    cursor: pointer !important;
    @media (--desktop) {
        padding-left: var(--xxl-spacing) !important;
    }
}

.newIcon {
    cursor: pointer !important;
    margin-left: 12px;
    @media (--desktop) {
        margin-left: var(--xxl-spacing) !important;
    }
}

.addPost {
    width: 45px;
    height: 45px;
    right: 0;
    border-left: 0;
    z-index: 1001;
    top: 0;
}

.showSearch {
    width: 45px;
    height: 45px;
    right: 0;
    border-left: 0;
    z-index: 1001;
}

.header {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0;
    background: var(--primary);
}

.menu {
    position: sticky;
    width: 50% !important;
    margin-left: auto;
    margin-right: 0;
    right: 0 !important;
    top: 32px !important;
    background: transparent !important;
    box-shadow: none !important;
}

.left10 {
    margin-left: 8px !important;
    height: 30px;
}

.right {
    font-size: var(--h5-font-size);
    color: var(--black80);
    padding-left: 8px;
    font-weight: var(--semi-bold);
    flex: 1;
    @media (--phone) {
        padding-left: 16px !important;
        width: 60%;
    }
}

.shop {
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    flex: 1;
}

.flex {
    display: flex;
    align-items: center;
}

.bigName {
}

.count {
    font-size: var(--xs-font-size);
    font-weight: var(--semi-bold);
    height: 14px;
    width: 14px;
    color: var(--secondary);
    border: 2px solid var(--secondary);
    background: var(--primary);
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.relative {
    position: relative;
}

.emptyIcon {
    cursor: pointer;
    height: auto !important;
    width: auto !important;
    border-radius: 0 !important;
    padding: var(--s-spacing);
}

.shopIcon {
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-left: var(--l-spacing);

        @media (--phone) {
            padding-left: 0 !important;
            background: none !important;
    }
}

.default {
    background: var(--secondary-grey);
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.shippingSteps {
    display: flex;
    align-items: center;
    @media (--desktop) {
        display: none;
    }
}


.step {
    padding: var(--xs-spacing);
    border-radius: var(--xs-spacing);
    font-size: var(--s-font-size);
    color: var(--black80);
    background: var(--grey);
    line-height: 1.5;
}

.stepName {
    padding-left: var(--xs-spacing);
    font-size: var(--xs-font-size);
    color: var(--black80);
}
