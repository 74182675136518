:global(.mobile) {

    .ctn {
        background-color: var(--primary);
        width: 100%;
      }

    .catalogs {
        margin-bottom: var(--m-spacing);
        display: flex;
        height: 100%;
        width: 100%;
        flex-wrap: wrap;
    }


    .catalogMore {
        background: #F4F4F4 !important;
        height: 98px;
        width: 98px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
    }

    .heading1 {
        font-size: var(--h1-font-size);
        font-weight: var(--light-bold);
        color: var(--primary);
        display: flex;
        margin-top: 1px;
        color: #F4F4F4;
        font-size: 30px;
        line-height: 34px;
        padding-left: var(--m-spacing);
    }


    .catalog {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        padding: var(--l-spacing) 0px var(--l-spacing);


        .catalogImg {
            width: 360px;
            height: 240px;
            border-radius: 0px;
            color: var(--black);
            font-size: var(--h4-font-size);
            font-weight: var(--semi-bold);
            object-fit: cover;
            flex-direction: column;


        }


        .noCatalogImg {
            width: 360px;
            height: 240px;
            border-radius: 0px;
            color: var(--black);
            font-size: var(--h4-font-size);
            font-weight: var(--semi-bold);
            object-fit: cover;
            flex-direction: column;
            display: flex;
            justify-content: center;
        }



        .catalogLabel {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: 16px;
            color: var(--secondary);
            text-transform: uppercase;
        }
    }
    .viewAllbtn {
        border-radius: 0;
        border: 1px solid var(--secondary);
        padding: 6px 36px;
        border-radius: var(--xl-border-radius);
        background-color: var(--primary);
        margin: var(--xxxxl-spacing);
        display: flex;
        justify-content: center;






        span {
            font-size: var(--h5-font-size);
            color: var(--primary5);
            margin-right: var(--s-spacing);
        }
    }


    .viewButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: var(--xl-spacing);

    }
    .heading {
        font-size: var(--h1-font-size);
        font-weight: var(--light-bold);
        color: #F4F4F4;
        display: flex;
        font-size: 30px;
        line-height: 34px;
        padding: 50px 0px 0px 16px;

    }

 }


 :global(.desktop) {

    .ctn {
        background-color: var(--primary);
      }


    .catalogs {
        margin-bottom: var(--m-spacing);
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        align-content: center;
        justify-content: center;
        margin-bottom: 0%;
    }


    .catalog {
        display: flex;
        flex-direction: column;
        align-items: Center;
        position: relative;




        .catalogImg {
            width: 400px;
            height: 490px;
            border-radius: 0px;
            color: var(--black);
            font-size: var(--h4-font-size);
            font-weight: var(--semi-bold);
            object-fit: cover;
            flex-direction: column;


        }


        .noCatalogImg {
            width: 400px;
            height: 490px;
            border-radius: 0px;
            color: var(--black);
            font-size: var(--h4-font-size);
            font-weight: var(--semi-bold);
            object-fit: cover;
            flex-direction: column;
            display: flex;
            justify-content: center;
        }


        .catalogLabel {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: 22px;
            color: var(--white);
            align-items: center;
            text-transform: uppercase;
        }


    }


    .viewAllbtn {
        border-radius: 0;
        border: 1px solid var(--secondary);
        padding: 6px 36px;
        border-radius: 20px;
        background-color: var(--primary);
        margin: var(--xxxxl-spacing);
        display: flex;
        justify-content: center;

        span {
            font-size: var(--h5-font-size);
            color: var(--primary5);
            margin-right: var(--s-spacing);
        }

    }


    .viewButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin-top: 10px;
        margin-bottom: 20px;

    }


    .catalogMore {
        background: #F4F4F4 !important;
        height: 142px;
        width: 142px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }
    .heading {
        font-size: var(--h1-font-size);
        font-weight: var(--light-bold);
        color: var(--primary);
        display: flex;
        /* justify-content: center; */
        padding-top: 72px;
        color: #F4F4F4;
        font-size: 60px;
        line-height: 70px;
        margin-left: 120px;
    }

    .heading1 {
        font-size: var(--h1-font-size);
        font-weight: var(--light-bold);
        color: var(--primary);
        display: flex;
        /* justify-content: center; */
        margin-top: 1px;
        color: #F4F4F4;
        font-size: 60px;
        line-height: 70px;
        margin-bottom: 50px;
        margin-left: 120px;
    }
 }


 .templates {
    position: relative;

    @media (--phone) {
        position: relative;
        justify-content: center;
        flex-direction: row;
    }


    .slideButton {
        position: absolute;
        z-index: 10;

        &:last-child {
            bottom: 373px;
            left: calc(100% - 110px);
        }

        &:first-child {
            top: 323px;
            left: 70px;
        }
    }


    @media (--phone) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
 }


 .template {
    display: flex;
    justify-content: center;
    width: 100%;


    .templateImage {
        width: 100%;
        height: 746px;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: 100% 100%;
        align-items: center;

        @media (--phone) {
            background-repeat: no-repeat;
            width: 414px;
            height: 205px;
            flex-direction: column;
            justify-content: center;
            background-size: 100% 100%;


        }
    }


    .tplTitle {
        font-weight: var(--semi-bold);
        font-size: var(--h4-font-size);
        color: var(--secondary);
        padding-bottom: var(--s-spacing);
        text-align: center;
    }


    .tplDesc {
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--secondary);
        text-align: center;
    }
 }


 .headerStyles {
    padding: 0px 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border: 2px solid var(--primary);

    @media (--phone) {
        height: 64px;
    }
 }
